import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import './index.scss';
// Import custom components
import store from './store';
import translations from './constants/translations'
import { getAllProducts } from './actions'
import Landing from './components/landing'


// Layouts
import Pets from './components/layouts/pets/main';

//Collection Shop Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
import CollectionRightSidebar from "./components/collection/collection-right-sidebar";
import CollectionFullWidth from "./components/collection/collection-full-width";
import CollectionMetro from "./components/collection/collection-metro";
import CollectionRegular from "./components/collection/collection-regular";
import CollectionPremium from "./components/collection/collection-premium";
import CollectionCombo from "./components/collection/collection-combo";
import CollectionMegaCombo from "./components/collection/collection-mega-combo";
import CollectionOffers from "./components/collection/collection-offers";

// Product Collection Pages
import CollectionCollar from "./components/collection/collection-collar";
import CollectionLeash from "./components/collection/collection-leash";
import CollectionHarness from "./components/collection/collection-harness";
import CollectionBodyBelt from "./components/collection/collection-body-belt";
import CollectionRope from "./components/collection/collection-rope";
import CollectionChain from "./components/collection/collection-chain";

//Search
import ProductsSearch from "./components/collection/products-search";
import ViewProduct from "./components/products/view-product";
import LeftSideBar from "./components/products/left-sidebar";
import RightSideBar from "./components/products/right-sidebar";
import NoSideBar from "./components/products/no-sidebar";
import LeftImage from "./components/products/left-image";
import RightImage from "./components/products/right-image";
import Accordian from "./components/products/accordian";
import ColumnLeft from "./components/products/column-left";
import ColumnRight from "./components/products/column-right";
import Column from "./components/products/column";
import Vertical from "./components/products/vertical";

// Features
import Layout from './components/app'
import Cart from './components/cart'
import Compare from './components/compare/index'
import wishList from './components/wishlist'
import checkOut from './components/checkout'
import orderSuccess from './components/checkout/success-page'

// Extra Pages
import aboutUs from './components/pages/about-us'
import PageNotFound from './components/pages/404'
import lookbook from './components/pages/lookbook'
import Login from './components/pages/login'
import Welcome from './components/pages/welcome'
import Register from './components/pages/register'
import Search from './components/pages/search'
import Collection from './components/pages/collection'
import ForgetPassword from './components/pages/forget-password'
import Contact from './components/pages/contact'
import Dashboard from './components/pages/dashboard'
import Faq from './components/pages/faq'
import Orders from './components/pages/orders'
import TermsOfUse from './components/pages/terms-of-use'
import ReturnAndRefunds from './components/pages/return-and-refunds'
import PrivacyPolicy from './components/pages/privacy-policy'
// Blog Pages
import RightSide from './components/blogs/right-sidebar'
import Details from './components/blogs/details'
import BlogPage from './components/blogs/blog-page'

// Theme Element
import ElementTitle from "./components/features/theme/element-title"
import ElementBanner from "./components/features/theme/element-banner";
import ElementSlider from "./components/features/theme/element-slider";
import ElementCategory from "./components/features/theme/element-category";
import ElementService from "./components/features/theme/element-service";
import ElementRatio from "./components/features/theme/element-ratio";

// Product Elements
import ElementProductBox from "./components/features/product/element-product-box"
import ElementProductSlider from "./components/features/product/element-product-slider"
import ElementProductNoSlider from "./components/features/product/element-product-no-slider"
import ElementMultipleSlider from "./components/features/product/element-multiple-slider"
import ElementProductTab from "./components/features/product/element-product-tab"

// Portfolio Features
import GridCols from "./components/features/portfolio/grid-cols"
import MasonaryGridCols from "./components/features/portfolio/masonary-grid-cols"


class Root extends React.Component {

    render() {
        store.dispatch(getAllProducts());

        return(
        	<Provider store={store}>
                <IntlProvider translations={translations} locale='en'>
				<BrowserRouter basename={'/'} >
					<ScrollContext>
						<Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Pets}/>
							{/* <Route path={`${process.env.PUBLIC_URL}/pets`} component={Landing}/> */}
                            <Layout>

                                {/*Routes For Layouts*/}
                                {/* <Route path={`${process.env.PUBLIC_URL}/fashion`} component={Fashion}/> */}

								{/*Routes For (Product Collection) */}
								<Route path={`${process.env.PUBLIC_URL}/left-sidebar/collection`} component={CollectionLeftSidebar}/>
								<Route path={`${process.env.PUBLIC_URL}/no-sidebar/collection`} component={CollectionNoSidebar}/>
								<Route path={`${process.env.PUBLIC_URL}/right-sidebar/collection`} component={CollectionRightSidebar}/>
								<Route path={`${process.env.PUBLIC_URL}/full-width/collection`} component={CollectionFullWidth}/>
								<Route path={`${process.env.PUBLIC_URL}/shop/regular`} component={CollectionRegular}/>
                                <Route path={`${process.env.PUBLIC_URL}/shop/premium`} component={CollectionPremium}/>
                                <Route path={`${process.env.PUBLIC_URL}/shop/combo`} component={CollectionCombo}/>
                                <Route path={`${process.env.PUBLIC_URL}/shop/mega-combo`} component={CollectionMegaCombo}/>
                                <Route path={`${process.env.PUBLIC_URL}/shop/offers`} component={CollectionOffers}/>
                                <Route path={`${process.env.PUBLIC_URL}/metro/collection`} component={CollectionMetro}/>
                                
								{/*Routes For Products*/}
                                <Route path={`${process.env.PUBLIC_URL}/product/collar`} component={CollectionCollar}/>
                                <Route path={`${process.env.PUBLIC_URL}/product/leash`} component={CollectionLeash}/>
                                <Route path={`${process.env.PUBLIC_URL}/product/harness`} component={CollectionHarness}/>
                                <Route path={`${process.env.PUBLIC_URL}/product/body-belt`} component={CollectionBodyBelt}/>
                                <Route path={`${process.env.PUBLIC_URL}/product/rope`} component={CollectionRope}/>
                                <Route path={`${process.env.PUBLIC_URL}/product/chain`} component={CollectionChain}/>

                                <Route path={`${process.env.PUBLIC_URL}/search`} component={ProductsSearch}/>

                                {/*Routes For Single Product*/}
								<Route path={`${process.env.PUBLIC_URL}/view/product/:id`} component={ViewProduct}/>
                                <Route path={`${process.env.PUBLIC_URL}/left-sidebar/product/:id`} component={LeftSideBar}/>
								<Route path={`${process.env.PUBLIC_URL}/right-sidebar/product/:id`} component={RightSideBar}/>
								<Route path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`} component={NoSideBar}/>
								<Route path={`${process.env.PUBLIC_URL}/col-left/product/:id`} component={ColumnLeft}/>
								<Route path={`${process.env.PUBLIC_URL}/col-right/product/:id`} component={ColumnRight}/>
								<Route path={`${process.env.PUBLIC_URL}/accordian/product/:id`} component={Accordian}/>
								<Route path={`${process.env.PUBLIC_URL}/column/product/:id`} component={Column}/>
								<Route path={`${process.env.PUBLIC_URL}/left-image/product/:id`} component={LeftImage}/>
								<Route path={`${process.env.PUBLIC_URL}/right-image/product/:id`} component={RightImage}/>
								<Route path={`${process.env.PUBLIC_URL}/vertical/product/:id`} component={Vertical}/>
								

								{/*Routes For custom Features*/}
								<Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
								<Route path={`${process.env.PUBLIC_URL}/wishlist`} component={wishList}/>
								<Route path={`${process.env.PUBLIC_URL}/compare`} component={Compare}/>
								<Route path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut}/>
								<Route path={`${process.env.PUBLIC_URL}/order-success`} component={orderSuccess}/>

								{/*Routes For Extra Pages*/}
                                <Route path={`${process.env.PUBLIC_URL}/about-us`} component={aboutUs}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/404`} component={PageNotFound}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/lookbook`} component={lookbook}/>
                                <Route path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
                                <Route path={`${process.env.PUBLIC_URL}/welcome`} component={Welcome}/>
                                {/* <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout}/> */}
                                <Route path={`${process.env.PUBLIC_URL}/register/:cashback?`} component={Register}/>
                                {/* <Route path={`${process.env.PUBLIC_URL}/register`} component={Register}/> */}
                                <Route path={`${process.env.PUBLIC_URL}/pages/search`} component={Search}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/collection`} component={Collection}/>
                                <Route path={`${process.env.PUBLIC_URL}/forget-password`} component={ForgetPassword}/>
                                <Route path={`${process.env.PUBLIC_URL}/orders`} component={Orders}/>
                                <Route path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                                <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}/>
                                <Route path={`${process.env.PUBLIC_URL}/terms-of-use`} component={TermsOfUse}/>
                                <Route path={`${process.env.PUBLIC_URL}/return-and-refunds`} component={ReturnAndRefunds}/>
                                <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy}/>

								{/*Features*/}
								{/*Theme Elements*/}
                                <Route path={`${process.env.PUBLIC_URL}/features/element-title`} component={ElementTitle}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-banner`} component={ElementBanner}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-slider`} component={ElementSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-category`} component={ElementCategory}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-service`} component={ElementService}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-ratio`} component={ElementRatio}/>

								{/*Product Elements*/}
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-box`} component={ElementProductBox}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-slider`} component={ElementProductSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-no-slider`} component={ElementProductNoSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-multiple-slider`} component={ElementMultipleSlider}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/element-product-tab`} component={ElementProductTab}/>

								{/*Portfolios*/}
                                <Route path={`${process.env.PUBLIC_URL}/features/portfolio-grid/:columns`} component={GridCols}/>
                                <Route path={`${process.env.PUBLIC_URL}/features/portfolio-masonary/:columns`} component={MasonaryGridCols}/>

								{/*Blog Pages*/}
                                <Route path={`${process.env.PUBLIC_URL}/blog/right-sidebar`} component={RightSide}/>
                                <Route path={`${process.env.PUBLIC_URL}/blog/details`} component={Details}/>
                                <Route path={`${process.env.PUBLIC_URL}/blog/blog-page`} component={BlogPage}/>

                                {/* <Route exact path="*" component={PageNotFound} /> */}
                            </Layout>
                         </Switch>
					  </ScrollContext>
					</BrowserRouter>
                </IntlProvider>
			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


